export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBrsCFlG8ypu23jyurwdPDDfKIgCjE06CA",
    authDomain: "due-diligence-works-sandbox.firebaseapp.com",
    projectId: "due-diligence-works-sandbox",
    storageBucket: "due-diligence-works-sandbox.appspot.com",
    messagingSenderId: "491667550901",
    appId: "1:491667550901:web:bebbebb3d80464c71884fc",
    measurementId: "G-80F5BK075K"
  },
  firestore: {
    experimentalForceLongPolling: true,
    ignoreUndefinedProperties: true
  }
};
